// export const SizeCard = (size: any) => {
//   if (size.images.length !== 0) {
//     console.log(size.images[0].url);
//   }
//   return (
//     <div>
//       {size.images.length !== 0 ? <img src={size.images[0].url} /> : null}
//       {size.width}
//     </div>
//   );
// };

import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
const SizeCard = ({ size, cardWidth }: any) => {
  const nav = useNavigate();
  const { shopName, orderPk } = useParams();
  const onClickCard = () => {
    sessionStorage.setItem("lastPosition", window.scrollY.toString());
    nav(`/order/${shopName}/${orderPk}/productDetail/${size.id}`);
  };
  console.log(cardWidth);
  let image =
    "https://imagedelivery.net/OsJkSw9Sjt8i9jgP9HvzGw/aa080608-d89d-4d7f-39cf-1c41bfc22c00/public";
  if (size && size.images.length !== 0) {
    image = size.images[0].url;
  }

  return (
    <div className="card" style={{ width: cardWidth }}>
      <img
        style={{ width: cardWidth, height: cardWidth }}
        onClick={onClickCard}
        src={image}
      />
      <div>
        <span>{size.name}</span>
      </div>
    </div>
  );
};

export default memo(SizeCard);
