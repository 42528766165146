export default function SubmitButton({ onClickButton }: any) {
  const buttonStyle = {
    fontSize: 20,
    background: "white",
    border: "none",
  };
  return (
    <button
      className="option-buttons"
      style={buttonStyle}
      onClick={onClickButton}
    >
      UPLOAD
    </button>
  );
}
