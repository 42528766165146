import { createBrowserRouter } from "react-router-dom";
import Home from "./routes/Home";
import Root from "./components/Root";
import NotFound from "./routes/NotFound";
import Workshops from "./routes/Workshops";
import About from "./routes/About";
import SizeCreate from "./routes/SizeCreate";
import Contact from "./routes/Contact";
import SizeView from "./routes/SizeView";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "workshops",
        element: <Workshops />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "project/size",
        element: <SizeView />,
      },
      {
        path: "project/size/create",
        element: <SizeCreate />,
      },
    ],
  },
]);
export default router;
