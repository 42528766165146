import { useState } from "react";
export default function TextInput({ text, setText }: any) {
  const [textCount, setTextCount] = useState(0);
  const onChangeText = (e: any) => {
    if (e.target.value.length > 200) {
      return;
    }
    setTextCount(e.target.value.length);
    setText(e.target.value);
  };
  return (
    <div>
      <textarea
        onChange={onChangeText}
        value={text}
        placeholder="감상을 적어주세요"
      />
      <div className="custom-message">{`(${textCount}/200)`}</div>
    </div>
  );
}
