import { useRef } from "react";
import { useState } from "react";
import "../scss/UploadPhoto.scss";
export default function UploadPhoto({ images, setImages }: any) {
  const [photoStorageLinks, setPhotoStorageLinks] = useState<string[]>([]);
  const onChangePhotoInput = (e: any) => {
    const targetFiles = (e.target as HTMLInputElement).files as FileList;
    const targetFilesArray = Array.from(targetFiles);
    if (targetFilesArray.length > 3) {
      let length = targetFilesArray.length;
      targetFilesArray.splice(3, length - 3);
      const selectedFilesURL: string[] = targetFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });
      const selectedFiles = targetFilesArray;
      setPhotoStorageLinks((prev: any) => prev.concat(selectedFilesURL));
      setImages((prev: any) => prev.concat(selectedFiles));
    } else {
      const selectedFilesURL: string[] = targetFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });
      const selectedFiles = targetFilesArray;
      setPhotoStorageLinks((prev: any) => prev.concat(selectedFilesURL));
      setImages((prev: any) => prev.concat(selectedFiles));
    }
  };
  const fileRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    fileRef?.current?.click();
  };
  const onClickDelete = (url: string) => {
    const newImages = [...images];
    const index = newImages.indexOf(url, 0);
    if (index > -1) {
      newImages.splice(index, 1);
    }
    setImages(newImages);
  };
  return (
    <>
      <input
        ref={fileRef}
        style={{ display: "none" }}
        onChange={onChangePhotoInput}
        type="file"
        multiple
        accept="image/jpeg,image/jpg,image/png"
      />
      <div className="photo-input">
        <button disabled={images.length >= 3} onClick={handleClick}>
          <img src="/camera.png" />
          <p>{images ? images.length : 0}/3</p>
        </button>
        {photoStorageLinks.map((url: string, i: number) => (
          <div key={url} className="preview-image-block">
            <img className="preview-image" key={url} src={url} />
            <button
              onClick={() => {
                onClickDelete(url);
              }}
            >
              <img className="delete-button" src="/delete_photo.png" />
            </button>
          </div>
        ))}
      </div>
    </>
  );
}
