import SizeCard from "./Card";
import { memo } from "react";
import { ISize } from "./type";
import "../scss/Grid.scss";
const Grid = ({
  contentWidth,
  sizes,
  productGroupName,
  checkIsSelected,
}: any) => {
  console.log(contentWidth);
  const cardWidth = 300;
  return (
    <div className="grid" style={{ width: contentWidth - 50 }}>
      {sizes?.map((size: ISize) => (
        <SizeCard
          cardWidth={contentWidth / 2}
          key={`${size.name} + ${size.width} + ${size.depth} + ${size.height}}`}
          checkIsSelected={checkIsSelected}
          size={size}
        />
      ))}
    </div>
  );
};
export default memo(Grid);
