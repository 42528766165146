import Header from "../components/Header";
import { getSizes } from "../api";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Grid from "../components/Grid";

export default function SizeView() {
  const [sizes, setSizes] = useState([]);
  const sizeQuery = useQuery({
    queryKey: ["sizes"],
    queryFn: getSizes,
  });
  //   const sizeQuery = useQuery({ queryKey: ["sizes"], queryFn: getSizes });
  useEffect(() => {
    if (sizeQuery.data) {
      console.log(sizeQuery.data);
      setSizes(sizeQuery.data);
    }
  }, [sizeQuery]);
  const nav = useNavigate();
  const onClickCreate = () => {
    nav(`/project/size/create`);
  };
  let storage = sessionStorage;
  let contentWidth = Number(storage.getItem("contentWidth"));

  return (
    <div>
      <Header />
      <button onClick={onClickCreate}>Create Size</button>
      <div className="title">Size Data Base</div>
      <Grid contentWidth={contentWidth} sizes={sizes} />
    </div>
  );
}
