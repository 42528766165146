import axios from "axios";
import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1/"
      : process.env.REACT_APP_BACKEND_TARGET_DOMAIN,
  withCredentials: true,
});
export const uploadWork = ({ text }: any) =>
  axiosInstance
    .post(
      `/order_requests/`,
      { text },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const uploadFile = (form_data: any) =>
  axiosInstance
    .post(`/files/`, form_data, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);

export const createSize = ({ form_data }: any) => {
  return axiosInstance
    .post(`sizes/`, form_data, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export async function getSizes({}) {
  const response = await axiosInstance.get(`sizes/`);
  return response.data;
}

axiosInstance.get(`sizes/`).then((response) => response.data);
// FILE UPLOAD
export const getUploadURL = ({ file }: any) =>
  axiosInstance
    .post(`sizes/get-url`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => ({ file: file, data: response.data }));
export const getUploadURLMultiple = ({ files }: any) =>
  axiosInstance
    .post(
      `sizes/get-url-multi`,
      { count: files.length },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => ({ files: files, data: response.data }));

export interface IUploadImageVariables {
  file: Blob;
  uploadURL: string;
}
export const uploadImage = ({ file, uploadURL }: IUploadImageVariables) => {
  const form = new FormData();
  form.append("file", file);
  return axios
    .post(uploadURL, form, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => response.data);
};

export const uploadImages = ({ files, uploadURLs }: any) => {
  let results = [];
  for (let i = 0; i < files.length; i++) {
    let result = uploadImage({ file: files[i], uploadURL: uploadURLs[i] });
    results.push(result);
  }
  return results;
};
export const customUploadImage = ({ file, uploadURL, fileName }: any) => {
  const form = new FormData();
  form.append("file", file);
  return axios
    .post(uploadURL, form, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      console.log(
        `${fileName}, https://imagedelivery.net/OsJkSw9Sjt8i9jgP9HvzGw/${response.data.result.id}/public`
      );
      return response.data;
    });
};
