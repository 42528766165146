import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import "../scss/Common.scss";
import UploadPhoto from "../components/UploadPhoto";
import TextInput from "../components/TextArea";
import { useState, useEffect, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { uploadImage, getUploadURLMultiple, createSize } from "../api";
import SubmitButton from "../components/SubmitButton";
import { zip } from "../utils";
interface IUploadURLResponse {
  id: string;
  uploadURL: string;
}

interface SizeResult {
  width: number;
  depth: number;
  height: number;
  name?: string;
  images?: string[]; // Define images as an optional property
  message?: string; // Define images as an optional property
}

const SizeInput = ({
  style,
  sizeKey,
  sizeDict,
  setSizeDict,
  isActive,
  onClickOption,
}: any) => {
  const [defaultValue, setDefaultValue] = useState(sizeDict[sizeKey]);
  const onChangeText = (e: any) => {
    setDefaultValue(e.target.value);
    setSizeDict((prev: any) => ({ ...prev, [sizeKey]: e.target.value }));
  };
  return (
    <div
      style={style}
      onClick={() => {
        onClickOption(sizeKey);
      }}
    >
      <div
        className={
          isActive ? "lettering_option_text_active" : "lettering_option_text"
        }
      >
        {sizeKey}
      </div>
      <input type="text" onChange={onChangeText} value={defaultValue} />
    </div>
  );
};
export default function SizeDataBase() {
  const nav = useNavigate();

  const optionList = ["width", "depth", "height"];
  const SizeDictOrigin = { width: 0, depth: 0, height: 0 };
  const [SizeDict, setSizeDict] = useState(SizeDictOrigin);
  const [activeOption, setActiveOption] = useState(optionList[0]);
  const onClickOption = (option: string) => {
    setActiveOption(option);
  };
  const color1 = "#0BAA5D";
  const optionButton = {
    width: "48%",
    background: "#F3F3F3",
    height: 75,
    borderRadius: "10px",
  };

  const optionActiveButton = {
    width: "48%",
    border: `1px solid ${color1}`,
    background: "#FFFFFF",
    height: 75,
    borderRadius: "10px",
  };
  // image 링크를 관리하는 Ref
  let imageLinksRef = useRef<string[]>([]);

  // image 업로드 mutation
  const uploadImageMutation = useMutation({
    mutationFn: uploadImage,
    onSuccess: (data) => {
      console.log("image upload single");
      imageLinksRef.current.push(
        `https://imagedelivery.net/OsJkSw9Sjt8i9jgP9HvzGw/${data.result.id}/public`
      );
    },
  });

  //size 데이터를 생성하는 mutation
  const createSizeMutation = useMutation({
    mutationFn: createSize,
    onSuccess: (response: any) => {
      alert("upload success");
      nav(`/project/size`);
    },
  });
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    if (isUploading && imageLinksRef.current.length === images.length) {
      let sizeData: SizeResult = { ...SizeDict };
      sizeData.name = name;
      sizeData.images = imageLinksRef.current;
      sizeData.message = text;
      //image url을 받아온 후에 size 데이터를 생성한다.
      // 그 후에는 image 중복 업로드를 막기 위해서 데이터를 제거한다.

      createSizeMutation.mutate({ form_data: sizeData });
      imageLinksRef.current = [];
      setIsUploading(false);
    }
  }, [imageLinksRef.current.length, isUploading]);
  const upLoadMultiple = async ({ files, data }: any) => {
    setIsUploading(true);
    // 이 for loop에서 upload image mutation이 끝나지 않았는데,
    // 함수가 모두 종료되어서 imageRefLinks가 업데이트 되지 않은 상태에서
    // createSizeMutation이 실행되어서 imageRefLinks가 비어있는 상태로 데이터가 생성된다.
    // 이를 해결하기 위해서는 모든 image upload mutation이 끝나고 나서 createSizeMutation을 실행해야한다.

    //https://kyounghwan01.github.io/blog/JS/JSbasic/for-await-of/#promise-all
    // 위 링크에서 해결하는데 성공했다. for await of 를 사용하거나
    // promise.all을 사용함으로서 해결할 수 있는 것으로 보인다.
    // for이나 foreach는 loop 내부의 비동기 함수의 실행을 기다리지 않는다.
    // for await 는 한 loop마다 한번씩
    // promise.all은 모든 loop가 끝난 후에 그 다음 함수를 실행하도록 한다.
    // 이건 꼭 기억하도록 하자.

    for await (let [file, url] of zip(files, data.uploadURL)) {
      uploadImageMutation.mutate({
        uploadURL: url,
        file: file as Blob,
      });
    }
  };

  const upLoadMultipleMutation = useMutation({
    mutationFn: upLoadMultiple,
    onSuccess: (response: any) => {},
    onError: (error) => {},
  });

  // image 업로드 url을 받아오는 mutation
  // image  개수별로 받아온다.
  const getUploadURLMultipleMutation = useMutation({
    mutationFn: getUploadURLMultiple,
    onSuccess: (response: any) => {
      const data: IUploadURLResponse = response.data;
      const files = response.files;

      upLoadMultipleMutation.mutate({ files: files, data: data });
      //file 별로 image urll들을 받아서 각각을 업로드한다.
      // upLoadMultiple({ files: files, data: data });
    },
    onError: (error) => {
      alert(error);
    },
  });

  const onClickSubmitButton = () => {
    if (name === "") {
      alert("이름을 입력해주세요");
      return;
    }
    if (images.length === 0) {
      alert("이미지를 업로드해주세요");
      return;
    }
    getUploadURLMultipleMutation.mutate({ files: images });
  };
  const [name, setName] = useState("");
  const onChangeName = (e: any) => {
    setName(e.target.value);
  };
  const [images, setImages] = useState<Blob[]>([]);
  const [text, setText] = useState("");

  return (
    <>
      <Header />
      <div className="title">Size Data Base</div>
      <div className="content">
        이 페이지는 테스트 중에 있습니다.
        <br />
        사이즈를 조사하고 정리하는 서비스입니다.
      </div>
      <div style={{ display: "flex" }}>
        <div className="content">이름</div>
        <input onChange={onChangeName} value={name} type="text" />
      </div>
      <div className="content">사이즈</div>
      <div>
        <div
          style={{
            marginLeft: 25,
            marginRight: 25,

            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {optionList?.map((option: string) => (
            <SizeInput
              key={option}
              sizeKey={option}
              sizeDict={SizeDict}
              setSizeDict={setSizeDict}
              style={
                option === activeOption ? optionActiveButton : optionButton
              }
              isActive={option === activeOption}
              onClickOption={onClickOption}
            />
          ))}
        </div>
        <div className="content">사진 업로드하기</div>
        <UploadPhoto images={images} setImages={setImages} />
        <div className="content">의견 남기기</div>
        <TextInput text={text} setText={setText} />
        <SubmitButton onClickButton={onClickSubmitButton} />
      </div>
    </>
  );
}
